import React from 'react';
import SEO from "../components/seo"
import Layout from "../components/layout"
import ContactForm from '../components/ContactForm';
import Grid from '@material-ui/core/Grid';
import SliderBootstrapStatic from '../components/SliderBootstrapStatic';


export default function Careers(){

    return(
        <Layout>
            <SEO 
                title="Careers"
                description="Contact Savante Consulting Limited. We will get back to you withn 24 hrs. Contact us Form."
                />
            <br /> <br /> <SliderBootstrapStatic  title="Want to join our team?" />
            <div className="container">
                
                <Grid container spacing={10}>
                        <Grid item xs={12} md={6} lg={6}>
                            <ContactForm />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            
                            <h5 className="mb-0">Savante Consulting Limited</h5>
                            
                            <p>
                                <a rel="noreferrer" 
                                    target="_blank" 
                                    href="https://www.google.ru/maps/place/Savante+Consulting+Com/@6.453666,3.3873153,17z/data=!3m1!4b1!4m5!3m4!1s0x103b8b568f3f5fc1:0xb2c59461ac52d3b7!8m2!3d6.453666!4d3.389504?hl=en">
                                      7th Floor Broad Street, NCR Building Lagos, Lagos Island, Lagos, Nigeria
                                </a>
                            </p>

                        <p>Office Line 1 : <a href="tel:23412916035">+234 12 91 6035</a></p>
                        Office Line 2 : <a href="tel:2348052448547">+234 805 244 8547</a>
                            
                            
                        </Grid>
                    </Grid>
                    <br /> 
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.535145140971!2d3.387315314770376!3d6.453665995331103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b568f3f5fc1%3A0xb2c59461ac52d3b7!2sSavante%20Consulting%20Com!5e0!3m2!1sen!2sru!4v1614100416116!5m2!1sen!2sru" 
                        width="100%" 
                        height="450" 
                        style={{border:0}} 
                        allowfullscreen="true" 
                        loading="lazy"
                        title="googleMaps"
                        >
                        
                    </iframe>
                    <br />
            </div>
        </Layout>
    );
}